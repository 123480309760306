<template>
  <div>
    <!--<div v-if="formListing!=='edit'" class="form-group row">-->
    <!--<label-->
    <!--class="col-12 col-lg-2 col-form-label text-lg-right"-->
    <!--v-html="$t('addListing.propCategory')"-->
    <!--&gt;</label>-->
    <!--<div class="col-lg-6 col-12" id="categoryProperty">-->
    <!--<div-->
    <!--class="btn-group btn-category w-100 my-lg-3"-->
    <!--data-toggle="buttons"-->
    <!--role="group">-->
    <!--<button-->
    <!--class="btn btn-light w-100"-->
    <!--v-for="propTypeCategoryGroup in propertyTypeCategoryGroupList"-->
    <!--:key="propTypeCategoryGroup.id"-->
    <!--@click="selectedPropertyTypeCategoryGroup=propTypeCategoryGroup.id"-->
    <!--:class="{'btn&#45;&#45;primary':propTypeCategoryGroup.selected}"-->
    <!--&gt;{{$t(`master.propType.category.group.${propTypeCategoryGroup.name}`)}}-->
    <!--</button>-->
    <!--</div>-->
    <!--</div>-->
    <!--<PopOverListingForm-->
    <!--target="categoryProperty"-->
    <!--:content="$t('addListing.hint.kategoriProperti')"/>-->
    <!--</div>-->
    <div class="form--group" :class="{ 'has-error': validation.hasError('selectedPropertyType') }">
      <div class="left--col">
        <label for="propertyType">
          <span>{{ $t('general.propertyType') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="right--col" id="propertyType">
        <!--<vue-tabs v-if="formListing!=='edit' && selectedPropertyTypeCategoryGroup!=null"-->
        <vue-tabs
          v-if="formListing !== 'edit'"
          class="tabs--property-type"
          v-model="activeTab"
          centered
        >
          <v-tab
            v-for="propTypeCategory in sortedPropertyTypeCategoryList"
            :key="propTypeCategory.id"
            :title="$t(`general.${propTypeCategory.name}`)"
            class="tab--content"
          >
            <!--v-if="propTypeCategory.propertyTypeCategoryGroupId == selectedPropertyTypeCategoryGroup.id">-->
            <div class="row">
              <div class="col-6">
                <div
                  class="w-100"
                  role="group"
                  v-for="(propertyType, index) in propertyTypeList[propTypeCategory.id]"
                  :key="propertyType.id"
                >
                  <button
                    v-if="index % 2 === 0"
                    :disabled="selectedExistingPropertyUuid"
                    @click.prevent="propertyTypeClicked(propertyType.id)"
                    :class="{ 'btn-primary': propertyType.selected }"
                    class="btn btn-light w-100 border-bottom"
                  >
                    {{ propertyType.name }}
                  </button>
                </div>
              </div>
              <div class="col-6">
                <div
                  class="w-100"
                  role="group"
                  v-for="(propertyType, index) in propertyTypeList[propTypeCategory.id]"
                  :key="propertyType.id"
                >
                  <button
                    v-if="index % 2 !== 0"
                    :disabled="selectedExistingPropertyUuid"
                    @click.prevent="propertyTypeClicked(propertyType.id)"
                    :class="{ 'btn-primary': propertyType.selected }"
                    class="btn btn-light w-100 border-bottom"
                  >
                    {{ propertyType.name }}
                  </button>
                </div>
              </div>
            </div>
          </v-tab>
        </vue-tabs>
        <div v-else class="listing--form-card">{{ selectedPropertyTypeName }}</div>
        <span class="val-error" v-if="validation.hasError('selectedPropertyType')">{{
          validation.firstError('selectedPropertyType')
        }}</span>
      </div>
      <PopOverListingForm target="propertyType" :content="$t('addListing.hint.tipe')" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { Validator } from 'simple-vue-validator';
import { VTab, VueTabs } from 'vue-nav-tabs';
import 'vue-nav-tabs/themes/vue-tabs.css';
import PopOverListingForm from '@/components/listing-form/pop-over-listing-form';

export default {
  name: 'property-type-section',
  components: {
    VueTabs,
    VTab,
    PopOverListingForm,
  },
  data() {
    return {
      activeTab: null,
    };
  },
  computed: {
    ...mapGetters({
      propertyTypeList: 'v2/listingForm/sect1/location/getPropertyTypeList',
      selectedPropertyType: 'v2/listingForm/sect1/location/selectedPropertyType',
      selectedExistingPropertyUuid: 'v2/listingForm/sect1/location/selectedPropertyUuid',
      selectedPropertyTypeName: 'v2/listingForm/sect1/location/selectedPropertyTypeName',
      selectedRentType: 'v2/listingForm/sect1/basicInfo/selectedRentTypeId',
      selectedRentPeriodIds: 'v2/listingForm/sect1/basicInfo/selectedRentPeriodIds',
      sortedPropertyTypeCategoryList: 'v2/listingForm/sect1/location/sortedPropertyCategoryList',
    }),
    ...mapState({
      formListing: state => state.v2.listingForm.form,
      allPropertyTypes: state => state.v2.listingForm.sect1.location.propertyTypeList,
      loadPropertyDataFromList: state =>
        state.v2.listingForm.sect1.location.loadPropertyDataFromList,
      propertyTypeCategoryGroupList: state =>
        state.v2.listingForm.sect1.location.propertyTypeCategoryGroupList,
      propertyTypeCategoryList: state =>
        state.v2.listingForm.sect1.location.propertyTypeCategoryList,
      locationTouched: state => state.v2.listingForm.sect1.location.isTouched,
      basicInfoTouched: state => state.v2.listingForm.sect1.basicInfo.isTouched,
      openBasicInfo: state => state.v2.listingForm.sect1.openBasicInfo,
    }),
    selectedPropertyTypeCategoryGroup: {
      get() {
        return this.$store.getters[
          'v2/listingForm/sect1/location/selectedPropertyTypeCategoryGroup'
        ];
      },
      set(value) {
        this.$store.commit(
          'v2/listingForm/sect1/location/SET_SELECTED_PROPERTY_TYPE_CATEGORY_GROUP_LIST',
          value,
        );
      },
    },
  },
  watch: {
    loadPropertyDataFromList(value) {
      if (!value) {
        const propTypeId = this.selectedPropertyType;
        const selectedPropertyType = this.allPropertyTypes.find(elem => {
          return elem.id == propTypeId;
        });
        if (selectedPropertyType) {
          const selectedPropTypeCategory = this.propertyTypeCategoryList.find(elem => {
            return elem.id == selectedPropertyType.propertyTypeCategoryId;
          });
          if (selectedPropTypeCategory) {
            // set selected category group sama category =====>
            this.$store.commit(
              'v2/listingForm/sect1/location/SET_SELECTED_PROPERTY_TYPE_CATEGORY_GROUP_LIST',
              selectedPropTypeCategory.propertyTypeCategoryGroupId,
            );
            this.$nextTick(() => {
              this.activeTab = this.$t(`master.propType.category.${selectedPropTypeCategory.name}`);
            });
            // <=====
          }
        }
      }
    },
  },
  methods: {
    propertyTypeClicked(propertyTypeId) {
      if (this.selectedRentPeriodIds.length != 0 || this.selectedRentType) {
        this.$swal({
          title: this.$i18n.t('warning.addListing.modal.typeChangeTitle'),
          text: this.$i18n.t('warning.addListing.modal.typeChangeMessage'),
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$i18n.t('general.btn.continue'),
          cancelButtonText: this.$i18n.t('general.btn.cancel'),
          allowOutsideClick: false,
        }).then(value => {
          if (value.value === true) {
            this.$store.dispatch('v2/listingForm/sect1/basicInfo/applyDefaultValue');
            this.changePropertyType(propertyTypeId);
          }
        });
      } else {
        this.changePropertyType(propertyTypeId);
      }
    },
    changePropertyType(propertyTypeId) {
      this.$store.commit('v2/listingForm/sect1/location/SET_ACTIVE_PROPERTY_TYPE', propertyTypeId);
      this.$store.commit('v2/listingForm/sect1/basicInfo/CHANGE_SIZEINSQUAREMETERS', '');
      this.$store.commit('v2/listingForm/sect1/basicInfo/SET_SELECTED_RENT_TYPE', null);
      this.$store.commit('v2/listingForm/sect1/SET_OPEN_OWNERSHIP', true);
      this.$store.dispatch('v2/listingForm/sect1/basicInfo/applyRentTypeRule', propertyTypeId);
      this.$store.dispatch('v2/listingForm/sect1/basicInfo/applyRentPeriodRule', propertyTypeId);
      this.$store.dispatch('v2/listingForm/sect2/detail/applyFacilityRule', propertyTypeId);
      if (!this.openBasicInfo) {
        this.$store.commit('v2/listingForm/sect1/SET_OPEN_BASIC_INFO', true);
      }
      this.$store.commit('v2/listingForm/sect1/SET_OPEN_DETAIL', true);
    },
    validate() {
      return this.$validate().then(
        function(success) {
          if (success) {
            return true;
          }
          return false;
        }.bind(this),
      );
    },
  },
  validators: {
    selectedPropertyType(value) {
      return Validator.value(value).required(
        this.$i18n.t('errors.addListing.propertyType.required'),
      );
    },
  },
};
</script>

<style lang="scss" scoped>
//.card--property {
//  .card-body {
//    @media #{$phones} {
//      padding: 0.5rem 1.25rem;
//    }
//  }
//}
</style>
